// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-connect-4-tsx": () => import("./../src/pages/connect4.tsx" /* webpackChunkName: "component---src-pages-connect-4-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-countdown-timer-tsx": () => import("./../src/pages/countdown-timer.tsx" /* webpackChunkName: "component---src-pages-countdown-timer-tsx" */),
  "component---src-pages-exercise-timer-tsx": () => import("./../src/pages/exercise-timer.tsx" /* webpackChunkName: "component---src-pages-exercise-timer-tsx" */),
  "component---src-pages-first-letter-last-letter-game-tsx": () => import("./../src/pages/first-letter-last-letter-game.tsx" /* webpackChunkName: "component---src-pages-first-letter-last-letter-game-tsx" */),
  "component---src-pages-food-world-cup-tsx": () => import("./../src/pages/food-world-cup.tsx" /* webpackChunkName: "component---src-pages-food-world-cup-tsx" */),
  "component---src-pages-greeting-tsx": () => import("./../src/pages/greeting.tsx" /* webpackChunkName: "component---src-pages-greeting-tsx" */),
  "component---src-pages-index-tsx": () => import("./../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-programming-projects-tsx": () => import("./../src/pages/programming-projects.tsx" /* webpackChunkName: "component---src-pages-programming-projects-tsx" */),
  "component---src-pages-stopwatch-tsx": () => import("./../src/pages/stopwatch.tsx" /* webpackChunkName: "component---src-pages-stopwatch-tsx" */),
  "component---src-pages-tic-tac-toe-jsx": () => import("./../src/pages/tic-tac-toe.jsx" /* webpackChunkName: "component---src-pages-tic-tac-toe-jsx" */),
  "component---src-pages-unclickable-button-tsx": () => import("./../src/pages/unclickable-button.tsx" /* webpackChunkName: "component---src-pages-unclickable-button-tsx" */),
  "component---src-pages-weather-tsx": () => import("./../src/pages/weather.tsx" /* webpackChunkName: "component---src-pages-weather-tsx" */)
}

